/*------------------------------------*/
/* require.js - 2009.11.22
/* http://tshinobu.com/lab/javascript/require/
/*------------------------------------*/
(function(){
    var s = document.getElementsByTagName("script");
    var d = s[s.length-1].src.substring(0, s[s.length-1].src.lastIndexOf("/")+1);
    for(var i=0; i<arguments.length; i++){
        document.write('<script type="text/javascript" src="'+d+arguments[i]+'"></script>');
    }
})(
/*-----------読み込むjs-----------*/
    "jquery-1.9.1.js",
    "jquery.easing.min.js",
    "animatedscrolltotop.js",
    "swimg.js",
    "tab.js",
    "opacity-rollover2.1.js",
    "analytics.js",
    "jqueryresponsivecrossfadeviewer.js",
    "slick/slick.js",
    "bootstrap/popper.js",
    "bootstrap/bootstrap.min.js",
    "bootstrap/bootstrap.bundle.min.js"
);
